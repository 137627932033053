import UpdatePasswordForm from '../../components/UpdatePasswordForm'
import Break from '../../components/Break'

const Security = () => {
    return (
        <div className="PgSecurity">
            <h1>SECURITY</h1>
            <Break />
            <h3>UPDATE PASSWORD</h3>
            <Break />
            <UpdatePasswordForm />
        </div>
    )
}

export default Security