import './Profile.css'
import Break from '../../components/Break'
import UpdateNameForm from '../../components/UpdateNameForm'
import UpdateEmailForm from '../../components/UpdateEmailForm'

const Profile = () => {
    return (
        <div className="PgProfile">
            <h1>PROFILE</h1>
            <Break />
            <div className="Frame">
                <div>
                    <h3>UPDATE NAME</h3>
                    <Break />
                    <UpdateNameForm />
                </div>
                <div>
                    <h3>UPDATE EMAIL</h3>
                    <Break />
                    <UpdateEmailForm />
                </div>
            </div>
        </div>
    )
}

export default Profile