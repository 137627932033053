import './ProductImage.css'
import { useEffect, useState } from 'react'
import SaleFlag from '../SaleFlag'
import Spinner from '../Spinner'

const ProductImage = ({ src, alt,    sale, onClick }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)

    useEffect(()=> {
        if (!src) {
            setIsLoading(false)
            setIsError(true)
        } else {
            setIsLoading(false)
            setIsError(false)
        }
    }, [src])
  
    const handleImageLoad = () => {
        setIsLoading(false)
    }
  
    const handleImageError = () => {
        setIsLoading(false)
        setIsError(true)
    }
  
    return (
        <div
            className="ImgProduct"
            onClick={onClick ?? null}
        >
            <img className={`Img${isLoading || isError ? ' Hidden' : ''}`} src={src} alt={alt} onLoad={handleImageLoad} onError={handleImageError} />
            {isLoading ? <div className="Loading">
                <Spinner />
            </div> : null}
            {isError ? <div className="Error">
                <svg viewBox="0 -15.25 128 128"><g transform="matrix(0.25, 0, 0, 0.25, 0, -15.25)"><path d="M0 61v390h512V61H0z M482 421H51.213L155 317.213l61 61l180-180l86 86V421z M482 241.787l-86-86l-180 180l-61-61l-125 125 V91h452V241.787z"></path><path d="M155 121c-33.084 0-60 26.916-60 60s26.916 60 60 60s60-26.916 60-60S188.084 121 155 121z M155 211 c-16.542 0-30-13.458-30-30s13.458-30 30-30s30 13.458 30 30S171.542 211 155 211z"></path></g></svg>
            </div> : null}
            {sale && <SaleFlag />}
        </div>
    )
}

export default ProductImage