import './LinkList.css'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'

const LinkList = ({ items }) => {
    const { pathname } = useLocation()

    return (
        <ul className="LstLink">
            {items.map((item, i) => {
                return (
                    <li className={`Item${item.to === pathname ? ' Selected' : ''}`} key={i}>
                        <Link to={item.to}>
                            {item.icon}
                            <span className="Label">
                                {item.label.toUpperCase()}
                            </span>
                        </Link>
                    </li>
                )
            })}
        </ul>
    )
}

export default LinkList