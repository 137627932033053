import './Header.css'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import React from 'react'
import SearchPanel from '../SearchPanel'
import MenuPanel from '../MenuPanel'
import LoginPanel from '../LoginPanel'
import Logo from '../Logo'
import { useLocation } from 'react-router'

const Header = () => {
    const [searchPanelIsOpen, setSearchPanelIsOpen] = useState(false)
    const [menuPanelIsOpen, setMenuPanelIsOpen] = useState(false)
    const [loginPanelIsOpen, setLoginPanelIsOpen] = useState(false)
    const { pathname } = useLocation()

    useEffect(() => {
        if (pathname === '/sign-in') setLoginPanelIsOpen(true)
    }, [pathname])

    return (
        <header className="Header">
            <div className="Container">
                <div>
                    <button className="BtnMenu" onClick={() => setMenuPanelIsOpen(true)}>
                        <svg viewBox="3.6 3.6 16.8 16.8" fill="inherit" stroke="inherit"><path fillRule="evenodd" clipRule="evenodd" d="M20.4 6.9H3.6v-1h16.8v1zm0 11.2H3.6v-1h16.8v1z"></path></svg>
                    </button>
                </div>
                <Link to="/">
                    <Logo />
                </Link>
                <div className="ContainerRight">
                    <nav>
                        <ul className="MenuSecondary">
                            <li>
                                <button onClick={() => setSearchPanelIsOpen(true)}>
                                    <svg viewBox="0 0 128 128"><g transform="matrix(0.2663298733278598, 0, 0, 0.2663298733278598, 0, 0)"><path d="M480.606 459.394L352.832 331.619c30.306-35.168 48.654-80.918 48.654-130.876C401.485 90.053 311.433 0 200.743 0 S0 90.053 0 200.743s90.053 200.743 200.743 200.743c49.958 0 95.708-18.348 130.876-48.654l127.775 127.775L480.606 459.394z M30 200.743C30 106.595 106.595 30 200.743 30s170.743 76.595 170.743 170.743s-76.595 170.743-170.743 170.743 S30 294.891 30 200.743z"></path></g></svg>
                                </button>
                            </li>
                            <li>
                                <Link to="/account">
                                    <svg viewBox="1.5 0 107.9 128"><g transform="matrix(6.484952597006087, 0, 0, 6.484952597006087, -8.551043797855433, 0)"><path d="M18.18 19.738h-2c0-3.374-2.83-6.118-6.311-6.118s-6.31 2.745-6.31 6.118h-2 c0-4.478 3.729-8.118 8.311-8.118C14.451 11.62 18.18 15.26 18.18 19.738z"></path><path d="M9.87 10.97c-3.023 0-5.484-2.462-5.484-5.485C4.385 2.461 6.846 0 9.87 0 c3.025 0 5.486 2.46 5.486 5.485S12.895 10.97 9.87 10.97z M9.87 2C7.948 2 6.385 3.563 6.385 5.485S7.948 8.97 9.87 8.97 c1.923 0 3.486-1.563 3.486-3.485S11.791 2 9.87 2z"></path></g></svg>
                                </Link>
                            </li> 
                        </ul>
                    </nav>
                </div>
            </div>
            <SearchPanel
                isOpen={searchPanelIsOpen}
                onRequestClose={() => setSearchPanelIsOpen(false)}
            />
            <MenuPanel
                isOpen={menuPanelIsOpen}
                onRequestClose={() => setMenuPanelIsOpen(false)}
            />
            <LoginPanel
                isOpen={loginPanelIsOpen}
                onRequestClose={() => setLoginPanelIsOpen(false)}
            />
        </header>
    )
}

export default Header