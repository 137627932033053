import axios from 'axios'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const ProductCategoriesService = {
    getCategoryById: async (productCategoryId) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/products/categories/${productCategoryId}`, {
                headers: {
                    Authorization: 'JWT ' + cookies.get('access_token')
                }
            })
            return res.data
        } catch (err) {
            return err.response ? err.response.data : null
        }
    },

    getCategories: async (sort, nested = false) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/products/categories?sort=${sort}&nested=${nested ? 'true' : 'false'}`, {
                headers: {
                    Authorization: 'JWT ' + cookies.get('access_token')
                }
            })
            return res.data
        } catch (err) {
            return err.response ? err.response.data : null
        }
    }
}

export default ProductCategoriesService