import './ProgressBar.css'

const ProgressBar = ({ progress }) => {
    return (
        <div className="ProgressBar">
            <div className="Progress" style={{ width: progress + '%' }}></div>
        </div>
    )
}

export default ProgressBar