import './SearchPanel.css'
import SearchInput from '../SearchInput'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { useRef, useEffect } from 'react'

const SearchPanel = ({ isOpen, onRequestClose }) => {
    const [search, setSearch] = useState('')
    const navigate = useNavigate()
    const searchRef = useRef()

    useEffect(() => {
        if (isOpen) searchRef.current.focus()
    }, [isOpen])

    const handleSearchEnterPress = () => {
        if (search.length >= 2) {
            navigate(`/search?q=${search}`)
            setSearch('')
            onRequestClose()
        }
    }

    return (
        <div className={`PanelSearch${isOpen ? ' Open' : ''}`}>
            <div className="Container">
                <div className="ContainerBtnClose">
                    <button className="BtnClose" onClick={onRequestClose}>
                        <svg viewBox="0 0 512 512"><g><path d="M512 28.308L483.692 0L256 227.692L28.308 0L0 28.308L227.692 256L0 483.692L28.308 512L256 284.308L483.692 512L512 483.692L284.308 256Z"></path></g></svg>
                    </button>
                </div>
                <div className="ContainerSearch">
                    <div className="Container">
                        <SearchInput
                            placeholder="SEARCH..."
                            value={search}
                            ref={searchRef}
                            onChange={(val) => setSearch(val)}
                            onEnterPress={handleSearchEnterPress}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchPanel