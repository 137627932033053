import './FormError.css'

const FormError = ({ message }) => {
    return (
        <div className="ErrFrm">
            {message}
        </div>
    )
}

export default FormError