import './PasswordInput.css'
import { useState } from 'react'

const PasswordInput = ({ placeholder, err, value, onChange }) => {
    const [isShow, setIsShow] = useState(false)

    return (
        <div className={`InputPass${err ? ' Err' : ''}`}>
            <input
                type={isShow ? 'text' : 'password'}
                placeholder={placeholder}
                value={value}
                onChange={(evt) => onChange(evt.target.value)}
            />
            <button
                type="button"
                className="BtnShow"
                onClick={() => setIsShow(!isShow)}
            >
                {isShow ? 'HIDE' : 'SHOW'}
            </button>
        </div>
    )
}

export default PasswordInput