import './Footer.css'

const Footer = () => {
    

    return (
        <footer className="Footer">
            <div className="Container">
                <h6 className="Copyright">&copy; {new Date().getFullYear()} Leopold Montague. All Rights Reserved.</h6>
            </div>
        </footer>
    )
}

export default Footer