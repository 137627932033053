import './App.css'
import Header from '../Header'
import Banner from '../Banner'
import Search from '../../pages/Search'
import { Routes, Route } from 'react-router-dom'
import ProductCategory from '../../pages/ProductCategory'
import Product from '../../pages/Product'
import Footer from '../Footer'
import Account from '../../pages/Account'
import PrivateRoute from '../PrivateRoute'
import Saves from '../../pages/Saves'
import Profile from '../../pages/Profile'
import Security from '../../pages/Security'
import ScrollToTop from '../../components/ScrollToTop'

const App = () => {
    return (
        <div className="App">
            <Banner message="Lorem ipsum dolor sit amet consectetur adipisicing elit." />
            <Header />
            <main>
                <Routes>
                    <Route path="/search" element={<Search />} />
                    <Route path="/products/categories/:slug" element={<ProductCategory />} />
                    <Route path="/products/:slug" element={<Product />} />
                    <Route path="account" element={<PrivateRoute><Account /></PrivateRoute>}>
                        <Route path="saves" element={<Saves />} />
                        <Route path="profile" element={<Profile />} />
                        <Route path="security" element={<Security />} />
                    </Route>
                </Routes>
            </main>
            <Footer />
            <ScrollToTop />
        </div>
    )
}

export default App