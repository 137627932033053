import axios from 'axios'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const UsersService = {
    updateName: async (userId, firstName, lastName) => {
        try {
            const res = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/users/${userId}/name`, {
                first_name: firstName,
                last_name: lastName
            }, {
                headers: {
                    Authorization: 'JWT ' + cookies.get('access_token')
                }
            })
            return res.data
        } catch (err) {
            return err.response ? err.response.data : null
        }
    },

    updateEmail: async (userId, email) => {
        try {
            const res = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/users/${userId}/email`, {
                email
            }, {
                headers: {
                    Authorization: 'JWT ' + cookies.get('access_token')
                }
            })
            return res.data
        } catch (err) {
            return err.response ? err.response.data : null
        }
    },

    updatePassword: async (userId, currentPassword, newPassword) => {
        try {
            const res = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/users/${userId}/password`, {
                current_password: currentPassword,
                new_password: newPassword
            }, {
                headers: {
                    Authorization: 'JWT ' + cookies.get('access_token')
                }
            })
            return res.data
        } catch (err) {
            return err.response ? err.response.data : null
        }
    },

    deleteUser: async (userId) => {
        try {
            const res = await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/users/${userId}`, {
                headers: {
                    Authorization: 'JWT ' + cookies.get('access_token')
                }
            })
            return res.data
        } catch (err) {
            return err.response ? err.response.data : null
        }
    }
}

export default UsersService