import TextInput from '../TextInput'
import Break from '../Break'
import Button from '../Button'
import { useState, useContext } from 'react'
import UsersService from '../../services/UsersService'
import { UserContext } from '../../contexts/UserContext'

const UpdateNameForm = () => {
    const { user, setUser } = useContext(UserContext)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [firstNameIsErr, setFirstNameIsErr] = useState(false)
    const [lastNameIsErr, setLastNameIsErr] = useState(false)

    const handleSubmit = (evt) => {
        evt.preventDefault()

        const isValid = validate()

        if (isValid) {
            UsersService.updateName(user.id, firstName, lastName).then(data => {
                if (data) {
                    if (data.meta.status_code === 200) {
                        const newUser = { ...user, first_name: firstName, last_name: lastName }
                        setUser(newUser)
                        alert('Success')
                    }
                    // TODO: Handle HTTP errs
                }
            })
        }
    }

    const validate = () => {
        let isValid = true

        setFirstNameIsErr(false)
        setLastNameIsErr(false)

        if (!firstName) {
            isValid = false
            setFirstNameIsErr(true)
        }

        if (!lastName) {
            isValid = false
            setLastNameIsErr(true)
        }

        return isValid
    }
    return (
        <form className="FrmUpdateName" onSubmit={handleSubmit}>
            <TextInput
                placeholder="FIRST NAME"
                err={firstNameIsErr}
                value={firstName}
                onChange={(val) => setFirstName(val)}
            />
            <Break />
            <TextInput
                placeholder="LAST NAME"
                err={lastNameIsErr}
                value={lastName}
                onChange={(val) => setLastName(val)}
            />
            <Break />
            <Button stretch>Save Name</Button>
        </form>
    )
}

export default UpdateNameForm