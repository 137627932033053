const formatPrice = (dec) => {
    let formatted = dec.toString()
    const split = formatted.split('.')
    const len = split[1] ? split[1].length : 0
    formatted += len === 0 ? '.00' : len === 1 ? '0' : ''
    return formatted
}

const calcDiscount = (pre, post) => {
    return Math.floor(((post - pre) / pre) * 100)
}

const isValidEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}

function isValidPassword(password) {
    // Check length
    if (password.length < 8) {
      return false
    }
  
    // Check for at least one capital letter
    if (!/[A-Z]/.test(password)) {
      	return false
    }
  
    // Check for at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      	return false
    }
  
    // Check for at least one number
    if (!/[0-9]/.test(password)) {
      	return false
    }
  
    // All criteria met
    return true
}

export {
    formatPrice,
    calcDiscount,
    isValidEmail,
    isValidPassword
}