import PasswordInput from '../PasswordInput'
import Break from '../Break'
import { useState, useContext } from 'react'
import Button from '../Button'
import { isValidPassword } from '../../util'
import UsersService from '../../services/UsersService'
import { UserContext } from '../../contexts/UserContext'
import FormError from '../FormError'

const UpdatePasswordForm = () => {
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [newPasswordIsErr, setNewPasswordIsErr] = useState(false)
    const [confirmNewPasswordIsErr, setConfirmNewPasswordIsErr] = useState(false)
    const { user } = useContext(UserContext)
    const [currentPassword, setCurrentPassword] = useState('')
    const [currentPasswordIsErr, setCurrentPasswordIsErr] = useState(false)
    const [formErr, setFormErr] = useState('')

    const handleSubmit = (evt) => {
        evt.preventDefault()

        const isValid = validate()

        if (isValid) {
            UsersService.updatePassword(user.id, currentPassword, newPassword).then(data => {
                if (data) {
                    if (data.meta.success_code === 200) {
                        alert('Success')
                    }

                    // TODO: Handle HTTP errs
                }
            })
        }
    }
    
    const validate = () => {
        let isValid = true

        setNewPasswordIsErr(false)
        setConfirmNewPasswordIsErr(false)

        if (!isValidPassword(currentPassword)) {
            isValid = false
            setCurrentPasswordIsErr(true)
        }

        if (!isValidPassword(newPassword)) {
            isValid = false
            setNewPasswordIsErr(true)
        }

        if (!isValidPassword(confirmNewPassword)) {
            isValid = false
            setConfirmNewPasswordIsErr(true)
        }

        if (!(newPassword === confirmNewPassword)) {
            isValid = false
            setNewPasswordIsErr(true)
            setConfirmNewPasswordIsErr(true)
        }

        return isValid
    }

    return (
        <form className="FrmUpdatePass" onSubmit={handleSubmit}>
            {formErr ? <>
                <FormError message={formErr} />
                <Break />
            </> : null}
            <PasswordInput
                placeholder="CURRENT PASSWORD"
                err={currentPasswordIsErr}
                value={currentPassword}
                onChange={(val) => setCurrentPassword(val)}
            />
            <Break />
            <PasswordInput
                placeholder="NEW PASSWORD"
                err={newPasswordIsErr}
                value={newPassword}
                onChange={(val) => setNewPassword(val)}
            />
            <Break />
            <PasswordInput
                placeholder="CONFIRM NEW PASSWORD"
                err={confirmNewPasswordIsErr}
                value={confirmNewPassword}
                onChange={(val) => setConfirmNewPassword(val)}
            />
            <Break />
            <Button stretch>SAVE PASSWORD</Button>
        </form>
    )
}

export default UpdatePasswordForm