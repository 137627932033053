import './ProductVariationPicker.css'
import { useState, useEffect } from 'react'
import Break from '../Break'

const ProductVariationPicker = ({ options, value, onChange }) => {
    const [colorNames, setColorNames] = useState([])
    const [materialNames, setMaterialNames] = useState([])
    const [sizeNames, setSizeNames] = useState([])
    const [selectedColorName, setSelectedColorName] = useState(null)
    const [selectedMaterialName, setSelectedMaterialName] = useState(null)
    const [selectedSizeName, setSelectedSizeName] = useState(null)
    const [lastSelectedCategory, setLastSelectedCategory] = useState(null)
    const [matchingVariations, setMatchingVariations] = useState([])

    useEffect(() => {
        const newColorNames = []
        const newMaterialNames = []
        const newSizeNames = []
        options.forEach(option => {
            if (option.color && !newColorNames.includes(option.color.name)) newColorNames.push(option.color.name)
            if (option.material && !newMaterialNames.includes(option.material.name)) newMaterialNames.push(option.material.name)
            if (option.size && !newSizeNames.includes(option.size.name)) newSizeNames.push(option.size.name)
        })
        setColorNames(newColorNames)
        setMaterialNames(newMaterialNames)
        setSizeNames(newSizeNames)
    }, [options])

    useEffect(() => {
        if (value.color) setSelectedColorName(value.color.name)
        if (value.material) setSelectedMaterialName(value.material.name)
        if (value.size) setSelectedSizeName(value.size.name)
        setLastSelectedCategory(value.color ? 'COLOR' : value.material ? 'MATERIAL' : value.size ? 'SIZE' : null)
    }, [value])

    useEffect(() => {
        const newMatchingVariations = getMatchingVariations(options, selectedColorName, selectedMaterialName, selectedSizeName)
        setMatchingVariations(newMatchingVariations)

        // Handle onChange
        if (selectedColorName !== null && selectedMaterialName !== null && selectedSizeName !== null) {
            if (newMatchingVariations.length) {
                const newValue = newMatchingVariations[0]
                onChange(newValue)
            }
        }
    }, [selectedColorName, selectedMaterialName, selectedSizeName])

    useEffect(() => {
        if (!matchingVariations.length) {
            if (lastSelectedCategory === 'COLOR') {
                setSelectedMaterialName(null)
                setSelectedSizeName(null)
            } else if (lastSelectedCategory === 'MATERIAL') {
                setSelectedColorName(null)
                setSelectedSizeName(null)
            } else if (lastSelectedCategory === 'SIZE') {
                setSelectedColorName(null)
                setSelectedMaterialName(null)
            }
        }
    }, [matchingVariations])

    const handleColorClick = (colorName) => {
        setSelectedColorName(colorName)
        setLastSelectedCategory('COLOR')
    }

    const handleMaterialClick = (materialName) => {
        setSelectedMaterialName(materialName)
        setLastSelectedCategory('MATERIAL')
    }

    const handleSizeClick = (sizeName) => {
        setSelectedSizeName(sizeName)
        setLastSelectedCategory('SIZE')
    }

    const getMatchingVariations = (variations, colorName, materialName, sizeName) => {
        return variations.filter(variation => (colorName ? variation.color ? variation.color.name === colorName : true : true) && (materialName ? variation.material ? variation.material.name === materialName : true : true) && (sizeName ? variation.size ? variation.size.name === sizeName : true : true))
    }


    const shouldBeDisabled = (key, value) => {
        const variation = matchingVariations.find(variation => variation[key] ? variation[key].name === value : false)
        return variation ? false : true
    }
    return (
        <div className="PickerProductVariation">
            {colorNames.length ? <>
                <h5>COLOR</h5>
                <Break size="8" />
                <ul>
                    {colorNames.map((colorName, i) => (
                        <li key={i}>
                            <button
                                className={colorName === selectedColorName ? 'Selected' : ''}
                                disabled={lastSelectedCategory === 'COLOR' ? false : shouldBeDisabled('color', colorName)}
                                onClick={() => handleColorClick(colorName)}
                            >
                                {colorName.toUpperCase()}
                            </button>
                        </li>
                    ))}
                </ul>
            </> : null}
            {materialNames.length ? <>
                <Break size="8" />
                <h5>MATERIAL</h5>
                <Break size="8" />
                <ul>
                    {materialNames.map((materialName, i) => (
                        <li key={i}>
                            <button
                                className={materialName === selectedMaterialName ? 'Selected' : ''}
                                disabled={lastSelectedCategory === 'MATERIAL' ? false : shouldBeDisabled('material', materialName)}
                                onClick={() => handleMaterialClick(materialName)}
                            >
                                {materialName.toUpperCase()}
                            </button>
                        </li>
                    ))}
                </ul>
            </> : null}
            {sizeNames.length ? <>
                <Break size="8" />
                <h5>SIZE</h5>
                <Break size="8" />
                <ul>
                    {sizeNames.map((sizeName, i) => (
                        <li key={i}>
                            <button
                                className={sizeName === selectedSizeName ? 'Selected' : ''}
                                disabled={lastSelectedCategory === 'SIZE' ? false : shouldBeDisabled('size', sizeName)}
                                onClick={() => handleSizeClick(sizeName)}
                            >
                                {sizeName.toUpperCase()}
                            </button>
                        </li>
                    ))}
                </ul>
            </> : null}
        </div>
    )
}

export default ProductVariationPicker