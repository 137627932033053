import { useState, useContext } from 'react'
import EmailInput from '../EmailInput'
import Break from '../Break'
import Button from '../Button'
import { isValidEmail } from '../../util'
import { UserContext } from '../../contexts/UserContext'
import UsersService from '../../services/UsersService'

const UpdateEmailForm = () => {
    const [email, setEmail] = useState('')
    const [emailIsErr, setEmailIsErr] = useState(false)
    const { user, setUser } = useContext(UserContext)

    const handleSubmit = (evt) => {
        evt.preventDefault()

        const isValid = validate()

        if (isValid) {
            UsersService.updateEmail(user.id, email).then(data => {
                if (data) {
                    if (data.meta.status_code === 200) {
                        const newUser = { ...user, email: data.email }
                        setUser(newUser)

                        alert('Success')
                    }

                    // TODO: Handle HTTP errs
                }
            })
        }
    }

    const validate = () => {
        let isValid = true

        setEmailIsErr(false)

        if (!isValidEmail(email)) {
            isValid = false
            setEmailIsErr(true)
        }

        return isValid
    }

    return (
        <form className="FrmUpdateEmail" onSubmit={handleSubmit}>
            <EmailInput placeholder="EMAIL" err={emailIsErr} value={email} onChange={(val) => setEmail(val)} />
            <Break />
            <Button stretch>Save Email</Button>
        </form>
    )
}

export default UpdateEmailForm