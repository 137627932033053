import ProductsService from '../../services/ProductsService'
import ProductList from '../../components/ProductList'
import { useParams } from 'react-router'
import { useState, useEffect } from 'react'
import LoadMore from '../../components/LoadMore'
import ProductCategoriesService from '../../services/ProductCategoriesService'
import Break from '../../components/Break'
import Loading from '../../components/Loading'
import PaddingContainer from '../../components/PaddingContainer'

const ProductCategory = () => {
    const [products, setProducts] = useState([])
    const { slug } = useParams()
    const [page, setPage] = useState(1)
    const [results, setResults] = useState(0)
    const requestResults = 50
    const [productCategoryId, setProductCategoryId] = useState(0)
    const [productCategory, setProductCategory] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    
    useEffect(() => {
        const slugSplit = slug.split('-')
        const newProductCategoryId = parseInt(slugSplit[slugSplit.length - 1])

        setProducts([])
        setPage(1)
        setResults(0)
        setProductCategoryId(newProductCategoryId)
    }, [slug])

    useEffect(() => {
        if (productCategoryId) {getProductCategory(productCategoryId)
            getProducts(productCategoryId, page)
        }
    }, [productCategoryId])

    function getProductCategory (productCategoryId) {
        ProductCategoriesService.getCategoryById(productCategoryId).then(data => {
            if (data) {
                if (data.meta.status_code === 200) {
                    setProductCategory(data.category)
                }
            }
        })
    }

    function getProducts (productCategoryId, page) {
        ProductsService.getProducts('', null, productCategoryId, null, null, 'relevance', requestResults, page).then(data => {
            if (data) {
                if (data.meta.status_code === 200) {
                    setProducts([ ...products, ...data.products ])
                    setResults(data.results)

                    setIsLoading(false)
                }
            }
        })
    }

    const handleLoadMore = () => {
        getProducts(productCategoryId, page + 1)

        setPage(page + 1)
    }

    return (
        <div className="PgProductCategory">
            {isLoading ? (
                <Loading />
            ) : <>
                <PaddingContainer>
                    {productCategory ? <>
                        <h1>{productCategory.name.toUpperCase() + ' (' + results + ' RESULTS)'}</h1>
                        <Break />
                    </> : null}
                </PaddingContainer>
                <ProductList products={products} />
                <LoadMore
                    requestResults={requestResults}
                    results={results}
                    page={page}
                    onRequestLoadMore={handleLoadMore}
                />
            </>}
        </div>
    )
}

export default ProductCategory