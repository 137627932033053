import './Banner.css'

const Banner = ({ message }) => {
    return (
        <p className="Banner">
            {message}
        </p>
    )
}

export default Banner