import './ProductImagePicker.css'

const ProductImagePicker = ({ options, value, onChange }) => {
    return options.length ? (
        <ul className="PickerProductImg">
            {options.map((option, i) => {
                return (
                    <li className={`Option${value ? option.id === value.id ? ' Selected' : '' : ''}`} key={i}>
                        <button onClick={() => onChange(option)}>
                            <img src={option.urls.small} alt="" />
                            <div className="Marker"></div>
                        </button>
                    </li>
                )
            })}
        </ul>
    ) : null
}

export default ProductImagePicker

// TODO: Add alt desc