import './ProductImagePanel.css'

const ProductImagePanel = ({ image, isOpen, onRequestPrevious, onRequestNext, onRequestClose }) => {
    return (
        <div className={`PanelProductImg${isOpen ? ' Open' : ''}`}>
            <div className="ContainerControls">
                <div className="ContainerCarouselBtns">
                    <button className="BtnPrev" onClick={onRequestPrevious ?? null}>
                        <svg viewBox="0 -16 128 128"><g transform="matrix(-0.25, 0, 0, 0.25, 128, -16.008499145507812)"><path d="M303.616 64.034L283.747 86.511L458.514 241L0 241L0 271L458.514 271L283.747 425.489L303.616 447.966L512 263.761L512 248.239Z"></path></g></svg>
                    </button>
                    <button className="BtnNext" onClick={onRequestNext ?? null}>
                        <svg viewBox="0 -16 128 128"><g transform="matrix(0.25, 0, 0, 0.25, 0, -16.008499145507812)"><path d="M303.616 64.034L283.747 86.511L458.514 241L0 241L0 271L458.514 271L283.747 425.489L303.616 447.966L512 263.761L512 248.239Z"></path></g></svg>
                    </button>
                </div>
                <div className="ContainerBtnClose">
                    <button className="BtnClose" onClick={onRequestClose}>
                        <svg viewBox="0 0 512 512"><g><path d="M512 28.308L483.692 0L256 227.692L28.308 0L0 28.308L227.692 256L0 483.692L28.308 512L256 284.308L483.692 512L512 483.692L284.308 256Z"></path></g></svg>
                    </button>
                </div>
            </div>
            <div className="ContainerProductImg">
                {image ? (
                    <img src={image.urls.large} alt="" />
                ) : null}
            </div>
        </div>
    )
}

export default ProductImagePanel