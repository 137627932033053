import './SaleFlag.css'

function SaleFlag() {
    return (
        <div className="FlagSale">
            <span>REDUCED PRICE</span>
            <div>
                <div className="Triangle top-triangle"></div>
                <div className="Triangle bottom-triangle"></div>
            </div>
        </div>
    )
}

export default SaleFlag