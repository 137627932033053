import axios from 'axios'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const ProductsService = {
    getProductById: async (productId) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/products/${productId}`, {
                headers: {
                    Authorization: 'JWT ' + cookies.get('access_token')
                }
            })
            return res.data
        } catch (err) {
            return err.response ? err.response.data : null
        }
    },

    getProducts: async (search, brandId, productCategoryId, productCollectionId, isSale, sort, results, page) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/products?search=${search}&brand=${brandId}&category=${productCategoryId}&collection=${productCollectionId}&sale=${isSale}&sort=${sort}&results=${results}&page=${page}`, {
                headers: {
                    Authorization: 'JWT ' + cookies.get('access_token')
                }
            })
            return res.data
        } catch (err) {
            return err.response ? err.response.data : null
        }
    }
}

export default ProductsService