import './LoginPanel.css'
import LoginForm from '../LoginForm'

const LoginPanel = ({ isOpen, onRequestClose }) => {
    return (
        <div className={`PanelLogin${isOpen ? ' Open' : ''}`}>
            <div className="Container">
                <div className="ContainerBtnClose">
                    <button className="BtnClose" onClick={onRequestClose}>
                        <svg viewBox="0 0 512 512"><g><path d="M512 28.308L483.692 0L256 227.692L28.308 0L0 28.308L227.692 256L0 483.692L28.308 512L256 284.308L483.692 512L512 483.692L284.308 256Z"></path></g></svg>
                    </button>
                </div>
                <div className="ContainerLogin">
                    <div className="Container">
                        <LoginForm />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginPanel