import { useLocation } from 'react-router'
import { useEffect } from 'react'

const ScrollToTop = () => {
    const { pathname } = useLocation()

    useEffect(() => {
        document.documentElement.scrollTop = 0
    }, [pathname])
}

export default ScrollToTop