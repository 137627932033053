import './BreadcrumbTrail.css'
import { Link } from 'react-router-dom'

const BreadcrumbTrail = ({ pages }) => {
    return (
        <ul className="BreadcrumbTrail">
            {pages.map((page, i) => {
                return (
                    <li>
                        {i !== pages.length - 1 ? <>
                            <Link to={page.to}>{page.label.toUpperCase()}</Link>&nbsp;/&nbsp;
                        </> : (
                            <span>{page.label.toUpperCase()}</span>
                        )}
                    </li>
                )
            })}
        </ul>
    )
}

export default BreadcrumbTrail