import './Center.css'

const Center = ({ children }) => {
    return (
        <div className="Center">
            {children}
        </div>
    )
}

export default Center