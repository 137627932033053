import './Price.css'
import { formatPrice, calcDiscount } from '../../util'

function Price({ price, salePrice }) {
    return price ? (
            <h3 className="Price">{salePrice ? <>
                <strike>{formatPrice(price)}</strike> <span className="sale">{calcDiscount(price, salePrice)}% {formatPrice(salePrice)} USD</span>
                </> : formatPrice(price) + ' USD'}
            </h3>
    ) : <></>
}

export default Price