import './MenuPanel.css'
import { useState, useEffect } from 'react'
import ProductCategoriesService from '../../services/ProductCategoriesService'
import { Link } from 'react-router-dom'
import Break from '../Break'

const MenuPanel = ({ isOpen, onRequestClose }) => {
    const [productCategories, setProductCategories] = useState([])
    const [productCategoriesIsCollapsed, setProductCategoriesIsCollapsed] = useState({})

    useEffect(() => {
        ProductCategoriesService.getCategories('name', true).then(data => {
            if (data) {
                if (data.meta.status_code === 200) {
                    const newProductCategoriesIsCollapsed = getProductCategoriesIsCollapsed(data.categories)

                    setProductCategoriesIsCollapsed(newProductCategoriesIsCollapsed)
                    setProductCategories(data.categories)
                }
            }
        })
    }, [])

    const getProductCategoriesIsCollapsed = (productCategories) => {
        let newProductCategoriesIsCollapsed = {}
      
        const processProductCategories = (productCategories) => {
            for (const productCategory of productCategories) {
                if (productCategory.children && productCategory.children.length > 0) {
                    newProductCategoriesIsCollapsed[`productCategory${productCategory.id}`] = true
                    processProductCategories(productCategory.children)
                }
            }
        }
      
        processProductCategories(productCategories)
      
        return newProductCategoriesIsCollapsed
    }

    const renderNestedCategoriesList = (categories) => {
        return (
            <ul className={categories.length ? productCategoriesIsCollapsed[`productCategory${getParentIdOfCategory(productCategories, categories[0].id)}`] ? 'Collapsed' : '' : ''}>
                {categories.map(category => {
                    return (
                        <li key={category.id}>
                            <div className="Container">
                                {category.children ? (
                                    <button className={productCategoriesIsCollapsed[`productCategory${category.id}`] ? 'Collapsed' : ''} onClick={() => handleProductCategoryCollapseClick(category.id)}>
                                        <span>{category.name.toUpperCase()}</span>
                                        <svg viewBox="0 0 128 70.4"><g transform="matrix(0.3141589897786635, 0, 0, 0.3141589897786635, 0, -28.76659663890723)"><path d="M386.258 91.567L203.718 273.512L21.179 91.567L0 112.815L203.718 315.87L407.437 112.815Z"></path></g></svg>
                                    </button>
                                ) : (
                                    <Link
                                        to={`/products/categories/${category.slug}`}
                                        onClick={onRequestClose}
                                    >
                                        {category.name.toUpperCase()}
                                    </Link>
                                )}
                                {category.children && renderNestedCategoriesList(category.children)}
                            </div>
					    </li>
                    )
                })}
            </ul>
		)
    }

    const getParentIdOfCategory = (categories, targetCategoryId) => {
        let parentId = null
      
        const processCategories = (categories) => {
            for (const category of categories) {
                if (category.id === targetCategoryId) {
                    return parentId // Found the target category, return its parent ID
                }
        
                if (category.children && category.children.length > 0) {
                    parentId = category.id // Update the current parent ID
                    const result = processCategories(category.children)
                if (result !== null) {
                    return result // Forward the result if the target is found in children
                }
            }
          }
      
          return null // Target category not found in this branch
        }
      
        return processCategories(categories);
    }

    const handleProductCategoryCollapseClick = (productCategoryId) => {
        let newProductCategoriesIsCollapsed = { ...productCategoriesIsCollapsed }
        newProductCategoriesIsCollapsed[`productCategory${productCategoryId}`] = !newProductCategoriesIsCollapsed[`productCategory${productCategoryId}`]
        setProductCategoriesIsCollapsed(newProductCategoriesIsCollapsed)
    }

    return (
        <nav className={`PanelMenu${isOpen ? ' Open' : ''}`}>
            <div className="Container">
                <div className="ContainerBtnClose">
                    <button className="BtnClose" onClick={onRequestClose}>
                        <svg viewBox="0 0 512 512"><g><path d="M512 28.308L483.692 0L256 227.692L28.308 0L0 28.308L227.692 256L0 483.692L28.308 512L256 284.308L483.692 512L512 483.692L284.308 256Z"></path></g></svg>
                    </button>
                </div>
                <div className="ContainerMenu">
                    <section className="ContainerProductCategoriesMenu">
                        <h1>SHOP BY CATEGORY</h1>
                        <Break />
                        {renderNestedCategoriesList(productCategories)}
                    </section>
                </div>
            </div>
        </nav>
    )
}

export default MenuPanel