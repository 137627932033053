import './ProductCard.css'
import Break from '../Break'
import { Link } from 'react-router-dom'
import Price from '../Price'
import ProductImage from '../ProductImage'

const ProductCard = ({ product, onClick }) => {
    return (
        <li className="CrdProduct">
            <Link to={`/products/${product.slug}`} onClick={onClick ? () => onClick(product.id, product.variation_id) : null}>
                <ProductImage
                    src={product.images.length ? product.images[0].urls.small : null}
                    alt={product.name}
                    sale={product.sale_price ? true : false}
                />
                <div className="ContainerInfo">
                    <div className="ContainerName">
                        <h3 className="Name">{product.name.toUpperCase()} - {product.brand.name.toUpperCase()}</h3>
                        <button className="BtnSave">
                            {product.user_saved ? <svg viewBox="0 0 88.8 128"><g transform="matrix(2.6630607264314543, 0, 0, 2.6630607264314543, -16.399127933047353, 0)" fill="none" stroke="none"></g><g transform="matrix(0.25, 0, 0, 0.25, -19.594249725341797, -17.44788087056125)"></g><g transform="matrix(0.25, 0, 0, 0.25, -19.594249725341797, 0)"></g><g transform="matrix(0.25, 0, 0, 0.25, -19.594499588012695, 0)"><path d="M78.378 0L78.378 510.536L261.686 367.451L433.622 512L433.622 0Z"></path></g></svg>
                                : <svg viewBox="0 0 88.8 128"><g transform="matrix(2.6630607264314543, 0, 0, 2.6630607264314543, -16.399127933047353, 0)" fill="none" stroke="none"></g><g transform="matrix(0.25, 0, 0, 0.25, -19.594249725341797, -17.44788087056125)"></g><g transform="matrix(0.25, 0, 0, 0.25, -19.594249725341797, 0)"><path d="M78.377 0v510.536l183.308-143.085L433.622 512h0.001V0H78.377z M403.742 447.842L262.385 329.001L108.259 449.306V29.881 h295.483V447.842z"></path></g></svg>}
                        </button>
                    </div>
                    <Break size="8px" />
                    <Price price={product.price} salePrice={product.sale_price} />
                    {product.variation_id ? <>
                        <Break size="8px" />
                        <div className="ContainerVariations">
                            {product.color ? (
                                <h5>
                                    <span>
                                        {product.color.toUpperCase()}
                                    </span>
                                    {product.colors.length > 1 ? `  +${product.colors.length - 1}` : null}
                                </h5>
                            ) : null}
                            {product.material ? (
                                <h5>
                                    <span>
                                        {product.material.toUpperCase()}
                                    </span>
                                    {product.materials.length > 1 ? ` +${product.materials.length - 1}` : null}
                                </h5>
                            ) : null}
                            {product.size ? (
                                <h5>
                                    <span>
                                        {product.size.toUpperCase()}
                                    </span>
                                    {product.sizes.length > 1 ? ` +${product.sizes.length - 1}` : null}
                                </h5>
                            ) : null}
                        </div>
                    </> : null}
                </div>
            </Link>
        </li>
    )
}

export default ProductCard