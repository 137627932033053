import { Navigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'

const PrivateRoute = ({ children }) => {
    const [cookies] = useCookies()

    if (!cookies.access_token) return <Navigate to="/sign-in" />

    return (
        <>
            {children}
        </>
    )
}

export default PrivateRoute