import Logo from '../Logo'
import EmailInput from '../EmailInput'
import { useState, useContext } from 'react'
import Break from '../Break'
import PasswordInput from '../PasswordInput'
import Button from '../Button'
import { isValidEmail, isValidPassword } from '../../util'
import AuthService from '../../services/AuthService'
import Center from '../Center'
import { UserContext } from '../../contexts/UserContext'
import { useCookies } from 'react-cookie'

const LoginForm = () => {
    const [username, setUsername] = useState('raineypiechowski@gmail.com')
    const [password, setPassword] = useState('My_L_Pass1')
    const { user, setUser } = useContext(UserContext)
    const [usernameIsErr, setUserNameIsErr] = useState(false)
    const [passwordIsErr, setPasswordIsErr] = useState(false)
    const [cookies, setCookie] = useCookies()

    const handleSubmit = (evt) => {
        evt.preventDefault()

        const isValid = validate()

        if (isValid) {
            AuthService.login(username, password).then(data => {
                if (data) {
                    if (data.meta.status_code === 200) {
                        // Set access_token cookie
                        const expires = new Date(Date.now() + (parseInt(data.expires_in) * 1000))
                        setCookie('access_token', data.access_token, {
                            path: '/',
                            domain:  process.env.REACT_APP_COOKIE_DOMAIN,
                            expires
                        })

                        // Update UserContext
                        setUser(data.user)
                    }
                }
            })
        }
    }

    const validate = () => {
        let isValid = true

        setUserNameIsErr(false)
        setPasswordIsErr(false)

        if (!isValidEmail(username)) {
            isValid = false
            setUserNameIsErr(true)
        }
        if (!isValidPassword(password)) {
            isValid = false
            setPasswordIsErr(true)
        }

        return isValid
    }

    return (
        <form onSubmit={handleSubmit} className="FrmLogin">
            <Center>
                <Logo />
            </Center>
            <Break />
            <h1>SIGN IN</h1>
            <Break />
            <EmailInput placeholder="EMAIL" err={usernameIsErr} value={username} onChange={(val) => setUsername(val)} />
            <Break />
            <PasswordInput placeholder="PASSWORD" err={passwordIsErr} value={password} onChange={(val) => setPassword(val)} />
            <Break />
            <Button stretch>Sign In</Button>
        </form>
    )
}

export default LoginForm