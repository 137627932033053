import './Search.css'
import { useSearchParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import ProductsService from '../../services/ProductsService'
import ProductList from '../../components/ProductList'
import LoadMore from '../../components/LoadMore'
import Break from '../../components/Break'
import PaddingContainer from '../../components/PaddingContainer'

const Search = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [search, setSearch] = useState('')
    const [products, setProducts] = useState([])
    const [page, setPage] = useState(1)
    const [results, setResults] = useState(0)
    const requestResults = 50

    useEffect(() => {
        const newSearch = searchParams.get('q') || ''
        setSearch(newSearch)

        setProducts([])
        setResults(0)
        setPage(1)
    }, [searchParams])

    useEffect(() => {
        if (search) {
            getProducts(search, results, page)
        }
    }, [search])

    function getProducts (search, page) {
        ProductsService.getProducts(search, null, null, null, null, 'relevance', requestResults, page).then(data => {
            if (data) {
                if (data.meta.status_code === 200) {
                    setProducts([ ...products, ...data.products ])
                    setResults(data.results)
                }
            }
        })
    }

    const handleLoadMore = () => {
        getProducts(search, page + 1)

        setPage(page + 1)
    }

    return (
        <div className="PgSearch">
            {results ? <>
                <PaddingContainer>
                    <h5>SHOWING {results} RESULTS FOR "{search.toUpperCase()}"</h5>
                </PaddingContainer>
                <Break />
                <ProductList products={products} />
                <LoadMore
                    requestResults={requestResults}
                    results={results}
                    page={page}
                    onRequestLoadMore={handleLoadMore}
                />
            </> : (
                <div className="NoResults">
                    <h5>NO RESULTS FOR "{search.toUpperCase()}"</h5>
                </div>
            )}
        </div>
    )
}

export default Search