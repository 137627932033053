import './TextInput.css'

const TextInput = ({ placeholder, err, value, onChange }) => {
    return (
        <input
            type="text"
            className={`InputTxt${err ? ' Err' : ''}`}
            placeholder={placeholder}
            value={value}
            onChange={(evt) => onChange(evt.target.value)}
        />
    )
}

export default TextInput