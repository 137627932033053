import './EmailInput.css'

const EmailInput = ({ placeholder, err, value, onChange }) => {
    return (
        <input
            type="email"
            className={`InputEmail${err ? ' Err' : ''}`}
            placeholder={placeholder}
            value={value}
            onChange={(evt) => onChange(evt.target.value)}
        />
    )
}

export default EmailInput