import './LoadMore.css'
import ProgressBar from '../ProgressBar'
import Break from '../Break'
import Button from '../Button'

const LoadMore = ({ requestResults, results, page, onRequestLoadMore }) => {
    const pages = Math.ceil(results / requestResults)
    const shownResults = page === pages ? results : results ? requestResults * page : 0
    const progress = results ? (shownResults / results) * 100 : 100

    return results ? (
        <div className="LoadMore">
            <div className="Container">
                <ProgressBar progress={progress} />
                <Break size="8" />
                <h6>Showing 1 - {shownResults} of {results}</h6>
                {progress < 100 ? <>
                    <Break size="12" />
                    <div className="ContainerBtn">
                        <Button onClick={onRequestLoadMore}>Load More</Button>
                    </div>
                </> : null}
            </div>
        </div>
    ) : <></>
}

export default LoadMore