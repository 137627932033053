import './SearchInput.css'
import React from 'react'

const SearchInput = React.forwardRef(({ placeholder, value, onChange, onEnterPress }, ref) => {
    const handleKeyDown = (evt) => {
        //handle onEventPress event
        if (evt.key === 'Enter') {
            if (onEnterPress) onEnterPress()
        }
    }

    return (
        <div className="InputSearch ColorLayer">
            <div className="ContainerIcon">
                <svg viewBox="0 0 128 128"><g transform="matrix(0.2663298733278598, 0, 0, 0.2663298733278598, 0, 0)"><path d="M480.606 459.394L352.832 331.619c30.306-35.168 48.654-80.918 48.654-130.876C401.485 90.053 311.433 0 200.743 0 S0 90.053 0 200.743s90.053 200.743 200.743 200.743c49.958 0 95.708-18.348 130.876-48.654l127.775 127.775L480.606 459.394z M30 200.743C30 106.595 106.595 30 200.743 30s170.743 76.595 170.743 170.743s-76.595 170.743-170.743 170.743 S30 294.891 30 200.743z"></path></g></svg>
            </div>
            <input type="text" placeholder={placeholder} ref={ref} value={value} onChange={(evt) => onChange(evt.target.value)} onKeyDown={handleKeyDown} />
        </div>
    )
})

export default SearchInput