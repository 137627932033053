import './Account.css'
import LinkList from '../../components/LinkList'
import { Outlet } from 'react-router-dom'

const Account = () => {
    return (
        <div className="PgAccount">
            <aside>
                <nav>
                    <LinkList
                        items={[
                            {
                                icon: <svg viewBox="0 0 88.8 128"><g transform="matrix(2.6630607264314543, 0, 0, 2.6630607264314543, -16.399127933047353, 0)" fill="none" stroke="none"></g><g transform="matrix(0.25, 0, 0, 0.25, -19.594249725341797, -17.44788087056125)"></g><g transform="matrix(0.25, 0, 0, 0.25, -19.594249725341797, 0)"></g><g transform="matrix(0.25, 0, 0, 0.25, -19.594499588012695, 0)"><path d="M78.378 0L78.378 510.536L261.686 367.451L433.622 512L433.622 0Z"></path></g></svg>,
                                label: 'Saves',
                                to: '/account/saves'
                            },
                            {
                                icon: <svg viewBox="1.5 0 107.9 128"><g transform="matrix(6.484952597006087, 0, 0, 6.484952597006087, -8.551043797855433, 0)"><path d="M18.18 19.738h-2c0-3.374-2.83-6.118-6.311-6.118s-6.31 2.745-6.31 6.118h-2 c0-4.478 3.729-8.118 8.311-8.118C14.451 11.62 18.18 15.26 18.18 19.738z"></path><path d="M9.87 10.97c-3.023 0-5.484-2.462-5.484-5.485C4.385 2.461 6.846 0 9.87 0 c3.025 0 5.486 2.46 5.486 5.485S12.895 10.97 9.87 10.97z M9.87 2C7.948 2 6.385 3.563 6.385 5.485S7.948 8.97 9.87 8.97 c1.923 0 3.486-1.563 3.486-3.485S11.791 2 9.87 2z"></path></g></svg>,
                                label: 'Profile',
                                to: '/account/profile'
                            },
                            {
                                icon: <svg viewBox="2 0 96 128"><g transform="matrix(8, 0, 0, 8, -14, 0)"><path d="M11,6.27814v-1.27814c0,-2.75684 -2.24316,-5 -5,-5c-2.75684,0 -5,2.24316 -5,5v1.27814c-0.59491,0.346863 -1,0.984741 -1,1.72186v6c0,1.10303 0.896973,2 2,2h8c1.10303,0 2,-0.896973 2,-2v-6c0,-0.737122 -0.40509,-1.375 -1,-1.72186Zm-5,-4.27814c1.6543,0 3,1.3457 3,3v1h-6v-1c0,-1.6543 1.3457,-3 3,-3Zm1,9.61072v1.38928h-2v-1.38928c-0.30481,-0.274536 -0.5,-0.668213 -0.5,-1.11072c0,-0.82843 0.67157,-1.5 1.5,-1.5c0.82843,0 1.5,0.67157 1.5,1.5c0,0.442505 -0.19519,0.836182 -0.5,1.11072Z" transform="matrix(1, 0, 0, 1, 2, 0)"></path></g></svg>,
                                label: 'Security',
                                to: '/account/security'
                            }
                        ]}
                    />
                </nav>
            </aside>
            <section>
                <Outlet />
            </section>
        </div>
    )
}

export default Account