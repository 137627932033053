import './Product.css'
import ProductsService from '../../services/ProductsService'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import ProductList from '../../components/ProductList'
import Break from '../../components/Break'
import LoadMore from '../../components/LoadMore'
import ProductImage from '../../components/ProductImage'
import Price from '../../components/Price'
import ProductVariationPicker from '../../components/ProductVariationPicker'
import LinkButton from '../../components/LinkButton'
import ProductImagePicker from '../../components/ProductImagePicker'
import ProductImagePanel from '../../components/ProductImagePanel'
import PaddingContainer from '../../components/PaddingContainer'
import BreadcrumbTrail from '../../components/BreakcrumbTrail'

const Product = () => {
    const [product, setProduct] = useState(null)
    const [productId, setProductId] = useState(0)
    const [ searchParams, setSearchParams ] = useSearchParams()
    const { slug } = useParams()
    const [productCategoryProducts, setProductCategoryProducts] = useState([])
    const [productCategoryProductsPage, setProductCategoryProductsPage] = useState(1)
    const [productCategoryProductResults, setProductCategoryProductsResults] = useState(0)
    const productCategoryProductRequestResults = 50
    const [productCategoryProductPage, setProductCategoryProductPage] = useState(1)
    const [productImages, setProductImages] = useState([])
    const [price, setPrice] = useState(0)
    const [salePrice, setSalePrice] = useState(null)
    const [selectedProductVariation, setSelectedProductVariation] = useState(null)
    const [normalURL, setNormalURL] = useState('')
    const [affiliateURL, setAffiliateURL] = useState('')
    const [productVariationId, setProductVariationId] = useState(0)
    const [selectedProductImage, setSelectedProductImage] = useState(null)
    const [productImageIsOpen, setProductImageIsOpen] = useState(false)

    useEffect(() => {
        const slugSplit = slug.split('-')
        const newProductId = parseInt(slugSplit[slugSplit.length - 1])

        setProductId(newProductId)
    }, [slug])

    useEffect(() => {
        const newProductVariationId = parseInt(searchParams.get('v'))
        if (newProductVariationId) setProductVariationId(newProductVariationId)
    }, [searchParams])

    useEffect(() => {
        if (productId) {
            getProduct(productId)
        }
    }, [productId])

    useEffect(() => {
        if (product) {
            const newProductImages = [ ...product.images, ...(product.collection ? product.collection.images : [])]
            setProductImages(newProductImages)

            // TODO: fix bug where products are fetched when product in url changes even when product category remains the same
            getProductCategoryProducts(product.category.id, productCategoryProductsPage)
        }
    }, [product])

    useEffect(() => {
        if (selectedProductVariation) {
            const newSearchParams   = new URLSearchParams()
            newSearchParams.set('v', selectedProductVariation.id)
            setSearchParams(newSearchParams)

            const newProductImages = [ ...selectedProductVariation.images, ...product.images, ...(product.collection ? product.collection.images : []) ]
            setProductImages(newProductImages)
            setPrice(selectedProductVariation.price)
            setSalePrice(selectedProductVariation.sale_price)
            setNormalURL(selectedProductVariation.normal_url)
            setAffiliateURL(selectedProductVariation.affiliate_url)
        }
    }, [selectedProductVariation])

    useEffect(() => {
        if (productImages.length) setSelectedProductImage(productImages[0])
    }, [productImages])

    function getProduct (productId) {
        ProductsService.getProductById(productId).then(data => {
            if (data) {
                if (data.meta.status_code === 200) {
                    setProduct(data.product)

                    setProductImages(data.product.images)
                    if (data.product.variations) {
                        const newSelectedProductVariation = data.product.variations.find(productVariation => productVariation.id === productVariationId)
                        if (newSelectedProductVariation) {
                            setSelectedProductVariation(newSelectedProductVariation)
                        }
                    } else {
                        setPrice(data.product.price)
                        setSalePrice(data.product.sale_price)
                        setNormalURL(data.product.normal_url)
                        setAffiliateURL(data.product.affiliate_url)
                    }
                }
            }
        })
    }

    function getProductCategoryProducts (productCategoryId, page) {
        ProductsService.getProducts('', null, productCategoryId, null, null, 'relevance', productCategoryProductRequestResults, page).then(data => {
            if (data) {
                if (data.meta.status_code === 200) {
                    setProductCategoryProducts([ ...productCategoryProducts, ...data.products ])
                    setProductCategoryProductsResults(data.results)
                }
            }
        })
    }

    const handleProductCategoryProductLoadMore = () => {
        getProductCategoryProducts(product.category.id, productCategoryProductPage + 1)

        setProductCategoryProductPage(productCategoryProductPage + 1)
    }

    const handleRequestPreviousProductImage = () => {
        if (selectedProductImage) {
            const currentIndex = productImages.indexOf(productImages.find(image => image.id === selectedProductImage.id))
            const prevIndex = currentIndex !== 0 ? currentIndex - 1 : productImages.length - 1
            const newSelectedProductImage = productImages[prevIndex]
            setSelectedProductImage(newSelectedProductImage)
        }
    }

    const handleRequestNextProductImage = () => {
        if (selectedProductImage) {
            const currentIndex = productImages.indexOf(productImages.find(image => image.id === selectedProductImage.id))
            const nextIndex = (productImages.length - 1) > currentIndex ? currentIndex + 1 : 0
            const newSelectedProductImage = productImages[nextIndex]
            setSelectedProductImage(newSelectedProductImage)
        }
    }

    return (
        <div className="PgProduct">
            {product ? <>
                <section className="SectionProduct">
                    <div>
                        <BreadcrumbTrail
                            pages={[
                                { to: '/', label: 'Home' },
                                { to: `/products/categories/${product.category.slug}`, label: product.category.name },
                                { label: product.name }
                            ]}
                        />
                    </div>
                    <div className="ContainerProduct">
                        <div className="ContainerSticky">
                            <div className="ContainerImg">
                                <ProductImage
                                    src={selectedProductImage ? selectedProductImage.urls.medium : ''}
                                    alt=""
                                    sale={salePrice ? true : false}
                                    onClick={() => setProductImageIsOpen(true)}
                                />
                            </div>
                            <div className="ContainerImgThumbnails">
                                    {<ProductImagePicker
                                        options={productImages}
                                        value={selectedProductImage}
                                        onChange={(val) => setSelectedProductImage(val)}
                                    />}
                                </div>
                        </div>
                        <div>
                            <div>
                                <div className="ContainerInfo">
                                    <h1>{product.name.toUpperCase()}</h1>
                                    <Break />
                                    <Price price={price} salePrice={salePrice} />
                                </div>
                                <div className="ContainerBuy">
                                    {product.variations ? <>
                                        <ProductVariationPicker
                                            options={product.variations}
                                            value={selectedProductVariation}
                                            onChange={(val) => setSelectedProductVariation(val)}
                                        />
                                        <Break />
                                    </> : null}
                                    <LinkButton stretch href={affiliateURL ? affiliateURL : normalURL} target="_blank">{affiliateURL ? 'AFFILIATE LINK' : 'PURCHASE'}</LinkButton>
                                    {affiliateURL ? <>
                                        <Break size="8" />
                                        <p>
                                            Shop smart, support us!
                                            Make your purchases through this affiliate link, and we earn a small commission to keep the site thriving.
                                        </p>
                                    </> : null}
                                </div>
                            </div>
                            {product.collection ? <>
                                <Break />
                                <h3>{product.collection.name.toUpperCase()}</h3>
                                <Break />
                                <section>
                                    <ProductList skinny products={product.collection.other_products} />
                                </section>
                            </> : null}
                        </div>
                    </div>
                </section>
                <Break size="128" />
                <section>
                    <PaddingContainer>
                        <h3>SHOP ALL {product.category.name.toUpperCase()}</h3>
                    </PaddingContainer>
                    <Break />
                    <ProductList products={productCategoryProducts} />
                </section>
            </> : null}
            <ProductImagePanel
                image={selectedProductImage}
                isOpen={productImageIsOpen}
                onRequestPrevious={handleRequestPreviousProductImage}
                onRequestNext={handleRequestNextProductImage}
                onRequestClose={() => setProductImageIsOpen(false)}
            />
        </div>
    )
}

export default Product