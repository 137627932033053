import './PaddingContainer.css'

const PaddingContainer = ({ children }) => {
    return (
        <div className="ContainerPadding">
            {children}
        </div>
    )
}

export default PaddingContainer