import axios from 'axios'

const AuthService = {
    login: async (username, password) => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/login`, {
                username,
                password
            })
            return res.data
        } catch (err) {
            return err.response ? err.response.data : null
        }
    },

    forgotPassword: async (email) => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/forgot-password`, {
                email
            })
            return res.data
        } catch (err) {
            return err.response ? err.response.data : null
        }
    },

    resetPassword: async (token, password) => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/reset-password`, {
                password
            }, {
                headers: {
                    Authorization: 'JWT ' + token
                }
            })
            return res.data
        } catch (err) {
            return err.response ? err.response.data : null
        }
    }
}

export default AuthService