import './ProductList.css'
import ProductCard from '../ProductCard'

function  ProductList({ skinny, products, onChange }) {
    return (
        <ul className={`LstProduct${skinny ? ' Skinny' : ''}`}>
            {products ? products.map((product, i) => {
                return <ProductCard key={i} product={product} onClick={onChange ? (productId, variationId) => onChange(productId, variationId) : null} />
            }) : null}
        </ul>
    )
}

export default ProductList